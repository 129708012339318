exports.linkResolver = function linkResolver(doc) {
  if (doc.type === "home") {
    return "/"
  }
  if (doc.type === "products") {
    return "/products"
  }
  if (doc.type === "product") {
    return "/products/" + doc.uid
  }
  if (doc.type === "blog") {
    return "/blog"
  }
  if (doc.type === "blog_post") {
    return "/blog/" + doc.uid
  }
  return "/"
}
