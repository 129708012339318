module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dku-portfolio","path":"/preview","previews":true,"pages":[{"type":"Product","match":"/products/:uid","path":"/product","component":"/opt/build/repo/src/pages/product.js"},{"type":"Blog_post","match":"/blog/:uid","path":"/blogpost","component":"/opt/build/repo/src/pages/blogPost.js"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113543937-1","head":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
